<mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center">
  Assets
</mat-toolbar>

<div class="cards" fxLayout="row wrap" fxLayoutAlign="stretch" fxLayoutGap="10px">
  <mat-card class="asset-card"
            [fxFlex]="(100/6) + '%'"
            fxFlex.xs="100%"
            fxFlex.sm="33%"
            fxLayout="column"
            fxLayoutAlign="space-between stretch"
            *ngFor="let asset of assets">
    <mat-card-header>
      <mat-card-title>{{ asset.name }}</mat-card-title>
      <mat-card-subtitle>{{ asset.desc }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p>Balance: {{ asset.balance }} Euro</p>
    </mat-card-content>
    <mat-card-actions align="end">
      <button style="float:right;" mat-button color="primary" (click)="routeTransfer(asset.id)">
        Transfer
      </button>
    </mat-card-actions>
  </mat-card>
</div>

<!-- Footer Section Pushed to the Bottom -->
<div id="footer-content" fxLayout="column" fxLayoutAlign="end center">
  <div style="align-items: center; text-align: center;">
    <h5>Your address: {{getPublicKey()}}</h5>
    <button mat-button color="primary" (click)="getZeroTokens()" title="Click Here to Request Demo Tokens">
      <mat-icon>account_balance_wallet</mat-icon>
      Request Demo Tokens
    </button>
  </div>
</div>
